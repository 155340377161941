<template>
  <v-dialog
    v-model="moreStore.orderModalOpen"
    persistent
    :fullscreen="isMobile"
    :max-width="isMobile ? undefined : 540"
    min-height="480px"
    scrollable
  >
    <v-card color="background" class="jw-dialog">
      <v-toolbar dark color="background" class="jw-dialog__header">
        <v-toolbar-items></v-toolbar-items>
        <v-toolbar-title class="today-adj" data-ko="교회/단체 구독 설정">
          교회/단체 구독 설정
          <!-- {{ $t("modal.grplist.title") }} -->
        </v-toolbar-title>
        <v-toolbar-items>
          <v-btn icon="jwIcons:svg/close" @click="moreStore.setOrderModal(false)"></v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <VCardText class="jw-dialog__body pa-5 pt-4">
        <!-- <div class="d-flex mt-3">
          <v-spacer />
          <VBtn variant="flat" height="25" rounded color="#2DA562" class="fs-12 px-3" @click.stop="findMyGroup()" data-ko="우리 교회/단체 찾기">{{
            $t("modal.grplist.btn.find")
          }}</VBtn>
        </div> -->
        <div class="desc">
          <img src="~/assets/images/logo.png" height="24" alt="Journal with Jesus logo" />
          <span class="txt">서비스에 등록된 여러분의 교회를 구독하세요!</span>
        </div>

        <draggable
          tag="ul"
          :list="moreStore.myGroupList"
          class="jw-order-list jw-order-list--mygrp jw-order-list--wrap pt-4"
          drag-class="dragging"
          ghost-class="ghost"
          handle=".handle"
          item-key="name"
          @start="moreStore.setDrag(true)"
          @end="moreStore.setDrag(false)"
        >
          <template #item="{ element, index }">
            <li class="jw-order-list__item">
              <div class="handle">
                <v-icon>jwIcons:svg/grap-line</v-icon>
              </div>
              <div class="title">{{ element.grpName }}</div>
              <v-spacer />
              <VBtn
                variant="text"
                :color="index >= 3 ? '#666' : '#fff'"
                min-width="auto"
                class="text-decoration-underline"
                @click.stop="cancelMyGroupAction(element)"
              >
                취소
              </VBtn>
              <!-- <div class="category">{{ element.grpCd }}</div> -->
              <!-- <v-btn icon size="small" class="visible" @click="element.displayYn === 'Y' ? element.displayYn = 'N' : element.displayYn = 'Y'">
                <v-icon v-if="element.displayYn === 'Y'">jwIcons:svg/eye</v-icon>
                <v-icon v-else>jwIcons:svg/eye-off</v-icon>
              </v-btn> -->
            </li>
          </template>
        </draggable>
        <div class="jw-order-list__item justify-center">
          <VBtn icon color="transparent" :ripple="false" @click.stop="gotoMyGroupAdd">
            <VIcon size="34" color="#666">jwIcons:svg/Plus</VIcon>
          </VBtn>
        </div>
      </VCardText>
      <div class="jw-group-regist-button-box">
        <div class="inner-box">
          <img src="~/assets/images/mng-tab-1.svg" height="21" alt="" />
          <span class="txt">아직 등록되지 않았다면?</span>
          <VBtn
            variant="text"
            color="white"
            class="text-decoration-underline px-0 fs-12"
            @click.stop="gotoOurGroupRegist"
            data-ko="우리 교회/단체 등록하기"
          >
            우리 교회/단체 등록하기
            <!-- {{ $t("more.regist.church") }} -->
          </VBtn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useMoreStore } from "~/store/more";
import { useAuthStore } from "~/store/auth";
import draggable from "vuedraggable";
import { useDisplay } from "vuetify";
import dayjs from "dayjs";

const { smAndDown, xlAndUp } = useDisplay();
const router = useRouter();
const isMobile = computed(() => smAndDown.value);

const moreStore = useMoreStore();
const authStore = useAuthStore();
//const { orderModalOpen = false, drag = false, dashboardList = [] } = storeToRefs(moreStore);

function gotoMyGroupAdd() {
  moreStore.setOrderModal(false);
  //router.push("/more/findMyGroup");
  router.push("/more/subscribeGroup");
}

function gotoOurGroupRegist() {
  moreStore.setOrderModal(false);
  router.push("/more/findMyGroup");
}

// 공동체 취소
async function cancelMyGroupAction(item) {
  try {
    const res = await moreStore.cancelMyGroupAction({
      grpCd: item.grpCd,
      grpName: item.grpName,
      memCd: authStore.userInfo.memCd,
    });

    if (res) await moreStore.getMyGroupList();
  } catch (err) {
    console.error(`cancelMyGroupAction modal err : `, err);
  }
}

/*
watch(
  () => moreStore.myGroupList,
  () => {
    console.log("moreStore.myGroupList ====> ", moreStore.myGroupList);
  },
  { deep: true, immediate: true }
);
 */
</script>

<style scoped>
.jw-dialog__body .desc {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #2da562;
}
.jw-dialog__body + .jw-group-regist-button-box {
  margin: 10px 0 28px;
}
.jw-order-list + .jw-order-list__item {
  margin-top: 20px;
}
</style>

<template>
  <!-- <swiper v-bind="defaultSwiperOption" class="jw-swiper" :autoplay="autoPlayOpt" :modules="swiperModules"> -->
  <!-- <swiper
    ref="bannerSwiper"
    v-if="!!bannerList && bannerList.length > 0"
    v-bind="defaultSwiperOption"
    class="jw-swiper"
    :modules="modules"
    :autoplay="{ delay: 5000, disableOnInteraction: false }"
    @swiper="activeSwiper"
  > -->
  <swiper
    v-if="bannerSwiper && !!bannerList && bannerList.length > 0"
    v-bind="defaultSwiperOption"
    class="jw-swiper"
    :modules="modules"
    :autoplay="{ delay: 5000, disableOnInteraction: false }"
    @swiper="activeSwiper"
    ref="bannerSwiper"
  >
    <swiper-slide ref="swiperSlides" v-for="(item, index) in bannerList" :key="`bannerList-${index}`" class="full">
      <NuxtLink :to="item.bnrLnk" :target="item.bnrTarget">
        <div class="jw-banner-card jw-banner-card--wrap">
          <VImg :src="item.bnrImg" :alt="item.postTitle" width="100%" aspect-ratio="100/19" cover>
            <template v-slot:placeholder>
              <div class="d-flex align-center justify-center fill-height">
                <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
              </div>
            </template>
          </VImg>
        </div>
      </NuxtLink>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from "swiper/modules";
import "swiper/css";

import { useMoreStore } from "~/store/more";
import { storeToRefs } from "pinia";
import { isEmpty } from "~/utils/utils";

const moreStore = useMoreStore();
const { bannerList: storeList = [] } = storeToRefs(moreStore);

const state = reactive({ swiper: null });

let bannerSwiper = ref(true);
let swiperSlides = ref();
/*
watch(
  () => bannerSwiper.value,
  (v) => {
    //console.log('배너 스와이퍼=======> ', v);
  },
  { deep: true, immediate: true }
);
 */
const bannerList = computed(() => {
  try {
    const list = storeList.value;
    if (isEmpty(list) || list.length === 0) return [];
    else return list;
  } catch (err) {
    console.error(`moreBanner bannerList err : `, err);
  }
});

const defaultSwiperOption = {
  slidesPerView: 'auto',
  slidesOffsetBefore: 20,
  slidesOffsetAfter: 20,
  spaceBetween: 10,
  loop: true,
};
//const swiperModules = [Autoplay];
const modules = [Autoplay];

function activeSwiper(swiperObj) {
  state.swiper = swiperObj;
}
/*
function onClickBanner(item) {
  // window.open(link, "_blank");
  if(item.)
} */

onBeforeMount(() => {});
/*
onBeforeRouteLeave(() => {
  try {
    bannerSwiper.value = false;
  } catch (err) {
    console.error(`moreBanner unmount err : `, err);
  }
}); */
</script>
